import React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, Text } from 'rebass/styled-components'

const imageWidth = 384 * 2
const imageHeight = 360 * 2

const Member = ({ name, position, children }) => {
  return (
    <Box variant="teamMember">
      {children}

      <Box variant="teamMemberContent">
        <Text variant="teamMemberName">{name}</Text>
        <Text variant="teamMemberPosition">{position}</Text>
      </Box>
    </Box>
  )
}

export const Team = () => (
  <Box variant="team">
    <Member name="Pavel" position="majitel, technický guru a designer">
      <StaticImage
        src="../images/pavel.jpg"
        alt="Pavel"
        quality="90"
        width={imageWidth}
        height={imageHeight}
      />
    </Member>
    <Member name="Olga" position="ředitelka a srdce celé firmy">
      <StaticImage
        src="../images/olga.jpg"
        alt="Olga"
        quality="90"
        width={imageWidth}
        height={imageHeight}
      />
    </Member>
    <Member name="Kryštof" position="obchodní, jazykový a IT specialista">
      <StaticImage
        src="../images/krystof.jpg"
        alt="Kryštof"
        quality="90"
        width={imageWidth}
        height={imageHeight}
      />
    </Member>
    <Member name="Filip" position="fotograf a marketingový junior specialista">
      <StaticImage
        src="../images/filip.jpg"
        alt="Filip"
        quality="90"
        width={imageWidth}
        height={imageHeight}
      />
    </Member>
    <Member name="Josef" position="specialista logistiky">
      <StaticImage
        src="../images/josef.jpg"
        alt="Josef"
        quality="90"
        width={imageWidth}
        height={imageHeight}
      />
    </Member>
  </Box>
)

Member.propTypes = {
  name: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}
