/* eslint-disable no-irregular-whitespace */
import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import {
  Box,
  Link as RebassLink,
  Heading,
  Text,
} from 'rebass/styled-components'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'
import { Hero } from '../components/hero'
import { IntroSection } from '../sections/intro'
import { ContentSection } from '../sections/content'
import { Team } from '../components/team'
import signature from '../images/safr-signature.svg'

const OurVisionPage = () => {
  const data = useStaticQuery(graphql`
    {
      heroBg: file(relativePath: { regex: "/nase-vize-bg.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
      introImage: file(relativePath: { regex: "/nase-vize-intro.jpg/" }) {
        childImageSharp {
          gatsbyImageData(quality: 90)
        }
      }
    }
  `)
  const heroBg = data.heroBg.childImageSharp.gatsbyImageData.images.fallback.src
  const introImage =
    data.introImage.childImageSharp.gatsbyImageData.images.fallback.src

  return (
    <Layout>
      <SEO title="Umíme pečovat o dřevo" />

      <Hero
        image={heroBg}
        bgPosition="center 50%"
        title="Umíme pečovat<br />o&nbsp;dřevo"
      />

      <IntroSection image={introImage}>
        <p>
          <strong>
            Pavel Šafr se povrchové úpravě věnuje už více než pětačtyřicet let.
            Začínal u kovových výrobků pro strojírenský průmysl, nakonec ho však
            oslovila povrchová úprava dřeva jako živého materiálu s nekonečně
            mnoha variantami úprav. Založil rodinnou firmu, jež s láskou, citem
            a šetrností pečuje o dřevo.
          </strong>
        </p>

        <p>
          Společnost FRYPO wood design pomáhá lidem vybrat takové produkty a
          postupy povrchové úpravy dřeva, které tomuto materiálu zajistí dlouhou
          životnost.
        </p>
      </IntroSection>

      <ContentSection sx={{ pt: 0 }}>
        <Heading variant="contentSectionTitle" as="h2">
          Když se zkušenosti potkají s inovacemi
        </Heading>

        <Text as="p">
          Aby si výrobky ze dřeva co nejdéle zachovaly svoje vlastnosti, je
          nutné je na povrchu ošetřit. Dřevo je totiž živý materiál, o který je
          potřeba náležitě pečovat.
        </Text>
        <Text as="p">
          My ve FRYPO wood design se právě na tuto péči specializujeme. Bereme
          přitom maximální ohled nejen na vysoké nároky na vzhled, funkčnost a
          kvalitu, ale i na šetrnost k životnímu prostředí.
        </Text>
        <Text as="p">
          K výběru nejvhodnější povrchové úpravy dřeva přistupujeme na základě
          dlouholetých zkušeností, které kombinujeme s nejnovějšími poznatky a
          inovativními metodami. Je pro nás naprosto přirozené, že vše, co
          nabízíme, předtím sami vyzkoušíme a otestujeme. Máme tak stoprocentní
          přehled o každém zboží z našeho katalogu.
        </Text>
      </ContentSection>

      <Box sx={{ maxWidth: '120rem' }}>
        <Team />
      </Box>

      <ContentSection dark>
        <Heading variant="contentSectionTitle" as="h2">
          Od návrhů odstínů po úpravy exotických dřevin
        </Heading>

        <Text as="p">
          Díky tomu jsme schopni každému zákazníkovi poradit individuálně a s
          ohledem na jeho požadavky mu předat naše znalosti, ať už se týkají:
        </Text>
        <Text as="ul">
          <li>
            kompletních návrhů barevných kompozit vnitřních obytných prostor i
            venkovních staveb,
          </li>
          <li>
            návrhů odstínů pro masivní i dýhovaný nábytek, schody a další
            dřevěné předměty,
          </li>
          <li>
            nebo realizací architektonických návrhů interiérů, a to včetně práce
            s exotickými a vzácnými dřevinami.
          </li>
        </Text>
      </ContentSection>

      <ContentSection>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: ['1fr', '1fr auto', '40rem auto'],
            gap: 5,
            justifyItems: 'center',
          }}
        >
          <Box variant="sections.content.boxContent">
            <Heading variant="contentSectionTitle" as="h2">
              Kráčíme s těmi, kdo to vidí stejně
            </Heading>

            <Text as="p">
              Klíčovým faktorem k tomu, abychom mohli svoji práci dělat dobře,
              je spolupráce s dodavateli s podobnou filozofií. Za dlouholeté
              partnerství se společnostmi Arboritec a Verinlegno jsme proto
              nesmírně vděční.
            </Text>
            <Text as="p">
              Naším potěšením jsou spokojení zákazníci. Pojďte se přidat mezi
              ně.
            </Text>
            <Text
              as="p"
              sx={{
                width: '200px',
                height: '124px',
                pt: '60px',
                backgroundImage: `url(${signature})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: '60%',
              }}
            >
              Pavel Šafr, majitel
            </Text>

            <RebassLink as={Link} variant="linkButton" to="/nabizime">
              Začít pečovat o dřevo
            </RebassLink>
          </Box>

          <StaticImage
            src="../images/safr-drop.png"
            alt="Pavel Šafr"
            placeholder="none"
            layout="fixed"
            width={247}
            height={343}
            quality={90}
          />
        </Box>
      </ContentSection>
    </Layout>
  )
}

export default OurVisionPage
